import React, { useEffect, useState } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { useLocation, useHistory } from "react-router";
import google from "../../../../assets/icons/google.svg";
import edit from "../../../../assets/icons/edit.svg";
import { loginWithGoogle } from "../../../../common/auth";
import { checkSpecialCharacters, getUrlVars, validateEmail } from "../../../../common/util";
import "./signup-form.css";
import { trackActionSegment } from "../../../../common/segment";
import { TriggerIssue } from "../../../../common/sentry"
export function SignUpForm(props) {
	const [validated, setValidated] = useState(false);
	const [selfSignUp, setSelfSignUp] = useState(false);
	const [userConsent, setUserConsent] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formContext, setFormContext] = useState();
	const [form, setForm] = useState();
	const [errors, setErrors] = useState({});
	const [errorDescription, setErrorDescription] = useState(null);
	const [userConsentError, setUserConsentError] = useState(null);
	const location = useLocation();
	const history = useHistory();


	useEffect(() => {
		if (location.search) {
			const param = getUrlVars(location.search);
			if (param?.error_description) {
				setErrorDescription(param.error_description);
			}
		}
		if (location?.state?.email) {
			setField("email", location?.state?.email);
		}
		if (!location?.state?.data?.code) {
			history.push("/invite-code");
		}
	}, [location]);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
	};

	useEffect(() => {
		setIsSubmitting(false);
		if (Object.entries(errors).length) {
			setErrors(findFormErrors());
		}
	}, [form]);

	const handleSubmit = (event) => {
		setFormContext(event.currentTarget);
		event.preventDefault();
		event.stopPropagation();
		const newErrors = findFormErrors(event.currentTarget);
		if (
			Object.keys(newErrors).length > 0 ||
			event.currentTarget.checkValidity() === false
		) {
			setErrors(newErrors);
		} else if (userConsent) {
			trackActionSegment("Signing up using email", {
				email: form?.email,
			});
			setIsSubmitting(true);
			window?.webAuth?.redirect?.signupAndLogin(
				{
					connection: "Username-Password-Authentication",
					email: form.email?.trim(),
					password: form.password,
					name: form.name,
					user_metadata: {
						signup_code: location?.state?.data?.code?.trim(),
					},
				},
				function (err) {
					const err_message = err.message || "Error in Username-Password-Authentication"
					TriggerIssue(err_message, err, "signup-client:Username-Password-Authentication");
					setIsSubmitting(false);
					if (err.code === "invalid_password") {
						setErrors({
							password: err.policy || err.description,
						});
						return;
					}
					switch (err.description) {
						case "Please enter work email address to continue":
							setErrors({
								email: "Please use your work email to sign up",
							});
							break;

						case "Special characters not allowed in the name field":
							setErrors({
								name: "Special characters not allowed in the name field",
							});
							break;
						case "The user already exists.":
						case "Email already exists":
						case "Invalid sign up":
							setErrors({
								email: "If you are a new user, a confirmation link will be sent to email provided.",
							});
							break;
						default:
							setErrors({
								email: err.description,
							});
							break;
					}
				}
			);
		} else {
			alert("User consent required!");
		}
		setValidated(true);
	};

	const findFormErrors = (context) => {
		const validateFormContext = context || formContext;
		const { name, email, password } = form;
		const newErrors = {};

		if (!name || name === "" || !validateFormContext[0].validity.valid || checkSpecialCharacters(name))
			newErrors.name = checkSpecialCharacters(name) ? "Please enter valid name" : "Please enter your name";
		if (
			!email ||
			!validateFormContext[1].validity.valid ||
			!validateEmail(email)
		)
			newErrors.email = "Please use your work email to sign up";
		if (
			!password ||
			password.length < 8 ||
			!validateFormContext[2].validity.valid
		)
			newErrors.password = "Your password must be atleast 8 characters";
		if (!userConsent) {
			setUserConsentError("Please agree terms and conditions")
		}
		return newErrors;
	};

	return (
		<div className="signup-form">
			<div>
				<h4 className="font-13 bold-500">
					<a href="/">Back</a>
				</h4>
			</div>
			<h3 className="bold-700 font-22 black-1 mb-4">Sign up to Zluri</h3>

			<div className="d-flex justify-content-between mb-3 p-3">
				<p className="font-14 black-1 o-8 m-0">Your Invite code</p>
				<p
					className="font-14 black-1 m-0 pointer"
					onClick={() => {
						history.push("/invite-code");
					}}
				>
					<span
						className="d-inline-block"
						style={{
							marginRight: "4px",
						}}
					>
						{location?.state?.data?.code}
					</span>
					<img className="pl-2" src={edit} />
				</p>
			</div>
			<ul className="px-3">
				<li className="mb-3">
					<button
						onClick={() => {
							if (!userConsent) {
								setUserConsentError("Please agree terms and conditions");
								return;
							}
							loginWithGoogle(location?.state?.data?.code);
						}}
						className={`btn font-14 ${selfSignUp
							? "btn-primary-inverse bg-white"
							: "btn btn-primary"
							}`}
					>
						<img
							width={30}
							alt=""
							src={google}
							className="ms-2 me-2"
						/>{" "}
						Signup with Google Workspace
					</button>
				</li>
				<li>
					{/* <button
								onClick={loginWithOffice}
								className={`btn font-14 ${
									selfSignUp
										? "btn-primary-inverse  bg-white"
										: "btn btn-primary"
								}`}
							>
								<img
									width={30}
									alt=""
									src={office}
									className="ms-2 me-2"
								/>{" "}
								Signup with Microsoft 365
							</button> */}
				</li>
				<li className="position-relative">
					<hr />
					<span className=" position-absolute top-50 start-50 translate-middle font-12 grey-1">
						or
					</span>
				</li>

				{!selfSignUp && (
					<li>
						<button
							onClick={() => {
								if (!userConsent) {
									setUserConsentError("Please agree terms and conditions");
									return;
								}
								setSelfSignUp(true)
							}}
							className="btn btn-primary-inverse font-14 text-center"
						>
							Signup with Email
						</button>
					</li>
				)}
			</ul>
			{selfSignUp && (
				<div className="self-signup-form mb-3">
					<Form
						noValidate
						validated={validated}
						onSubmit={handleSubmit}
					>
						<Form.Group as={Row} className="mb-2" controlId="name">
							<Form.Label>Your Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Name"
								required
								onChange={(e) =>
									setField("name", e.target.value)
								}
								isInvalid={!!errors.name}
							/>
							<Form.Control.Feedback
								type="invalid"
								className="font-12 ps-0"
							>
								{errors.name}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Row} className="mb-2" controlId="email">
							<Form.Label>Your work e-mail</Form.Label>
							<Form.Control
								type="email"
								placeholder="name@company.com"
								required
								value={form?.email}
								onChange={(e) =>
									setField("email", e.target.value)
								}
								isInvalid={!!errors.email}
							/>
							<Form.Control.Feedback
								type="invalid"
								className="font-12 ps-0"
							>
								{errors.email}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group
							as={Row}
							className="mb-2"
							controlId="password"
						>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="at least 8 characters"
								required
								minLength={8}
								onChange={(e) =>
									setField("password", e.target.value)
								}
								isInvalid={!!errors.password}
							/>
							<Form.Control.Feedback
								type="invalid"
								className="font-12 ps-0"
							>
								{errors.password}
							</Form.Control.Feedback>
						</Form.Group>
						<button
							type="submit"
							className="btn btn-primary font-14 text-center mt-3"
							disabled={isSubmitting}
						>
							{isSubmitting && (
								<span style={{ marginRight: "10px" }}>
									<Spinner size="sm" animation="border" />
								</span>
							)}{" "}
							Sign Up
						</button>
					</Form>
				</div>
			)}
			{errorDescription && (
				<p className="font-12 red ms-3">{errorDescription} </p>
			)}
			{userConsentError && <p className="font-12 red ms-3">{userConsentError}</p>}
			<div className="d-flex font-12">
				<Form.Check
					checked={userConsent}
					type="checkbox"
					style={{ width: "15px" }}
					onChange={(e) => {
						setUserConsent(e.target.checked);
						setUserConsentError(null);
					}}
				/>
				<p className="ms-1">
					By creating an account you agree to the{" "}
					<a
						className="a-blue"
						rel="noreferrer"
						href="https://www.zluri.com/zluri-app-terms-of-service"
						target="_blank"
					>
						Terms of Use{" "}
					</a>
					and our{" "}
					<a
						className="a-blue"
						rel="noreferrer"
						href="https://www.zluri.com/privacy-policy"
						target="_blank"
					>
						Privacy Policy
					</a>
				</p>
			</div>
		</div>
	);
}
