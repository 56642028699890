import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../../common/util";
import "./enter-email.css";
import Axios from "axios";
import { trackActionSegment } from "../../../../common/segment";
import client from "../../../../axios/client";
import { TriggerIssue } from "../../../../common/sentry"

export function EnterEmail(props) {
	const [email, setEmail] = useState("");
	const history = useHistory();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isError, setIsError] = useState(false);

	const handleSubmit = () => {
		if (validateEmail(email)) {
			const _email = email?.trim();
			let domain = _email?.split("@");
			domain = domain[domain.length - 1];
			trackActionSegment("Validate email", { domain });
			setIsSubmitting(true);
			client.post("/validate-domain", {
				domain: domain,
			})
				.then((res) => {
					if (res.data.success) {
						history.push("/request", {
							email: email,
						});
					} else {
						TriggerIssue("Error response from API", res.data, "signup:validate-domain")
						setIsError(true);
					}
				})
				.catch((err) => {
					const err_message = err.message || "Error response from API";
					TriggerIssue(err_message, err, "signup:validate-domain")
					setIsError(true);
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		} else {
			TriggerIssue("Email is invalid", email, "signup-client:validate-domain")
			setIsError(true);
		}
	};

	return (
		<div className="invite-code ">
			<h3 className="bold-700 font-22 black-1 mb-1">
				Activate your free account
			</h3>

			<p className="font-12 grey mb-3">
				Get the full Zluri experience for 14 days. Its free! No credit
				card, no hassle
			</p>

			<label className="font-12 black o-5">Your work email</label>
			<input
				value={email}
				type="email"
				onChange={(e) => {
					setEmail(e.target.value);
					setIsError(false);
				}}
				placeholder="name@company.com"
			/>
			{isError && (
				<p className="font-12 red mt-1 ml-0">
					Please enter valid work email address
				</p>
			)}

			<button
				className="btn btn-primary font-14 text-center mt-3"
				disabled={!email}
				onClick={() => {
					handleSubmit();
				}}
			>
				{isSubmitting && (
					<span style={{ marginRight: "10px" }}>
						<Spinner size="sm" animation="border" />
					</span>
				)}{" "}
				Continue
			</button>
		</div>
	);
}
