import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "./invite-code.css";
import { useHistory, useLocation } from "react-router-dom";
import { trackActionSegment } from "../../../../common/segment";
import client from "../../../../axios/client";
import { TriggerIssue } from "../../../../common/sentry";
import { validateEmail } from "../../../../common/util";
export function InviteCode(props) {
	const location = useLocation();
	const [inviteCode, setInviteCode] = useState("");
	const [userEmail, setUserEmail] = useState(
		location.state?.data?.email ? location.state?.data?.email : ""
	);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isError, setIsError] = useState(false);
	const history = useHistory();

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		if (query.has("code")) {
			setInviteCode(query.get("code"));
			handleSubmit(query.get("code"));
		}
	}, []);

	const handleSubmit = (code) => {
		setIsSubmitting(true);
		trackActionSegment("Validate invite code", { code });
		if (!validateEmail(userEmail)) {
			TriggerIssue(
				"Email is invalid",
				userEmail,
				"signup-client:v2/validate-invite-code"
			);
			setIsError(true);
		}
		const invite_code = code || inviteCode
		if (invite_code?.trim().indexOf("ZLR-") !== 0) {
			setIsError(true);
			setIsSubmitting(false);
			return;
		}
		if (invite_code?.trim().length !== 10) {
			setIsError(true);
			setIsSubmitting(false);
			return;
		}
		client
			.post("/v2/validate-invite-code", {
				code: invite_code?.trim(),
				email: userEmail?.trim(),
			})
			.then((res) => {
				if (res.data.success && res.data.new_user) {
					history.push("/signup", {
						data: res.data.code,
						email: location.state?.data?.email,
					});
				} else if (res.data.success && !res.data.new_user) {
					const localStorageFE = localStorage.getItem("frontendUrl");
					const authorize_params = {
						redirectUri: localStorageFE ? `${localStorageFE}/onboarding` : `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/onboarding`,
						audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
						scope: "openid profile email offline_access",
						responseType: "code",
						login_hint: userEmail
					}
					if (res.data.connection_name !== null) {
						authorize_params.connection = res.data.connection_name;
					}
					window.webAuth.authorize(authorize_params);
				} else {
					TriggerIssue(
						"Error response from API",
						res.data,
						"signup:v2/validate-invite-code"
					);
					setIsError(true);
				}
			})
			.catch((err) => {
				const err_message = err.message || "Error response from API";
				TriggerIssue(
					err_message,
					err,
					"signup:v2/validate-invite-code"
				);
				console.log(err);
				setIsError(true);
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const onChangeHandler = (e) => {
		setIsError(false);
		setInviteCode(e.target.value);
	};

	const onChangeUserEmailHandler = (e) => {
		setIsError(false);
		setUserEmail(e.target.value);
	};

	return (
		<div className="invite-code">
			<h3 className="bold-700 font-22 black-1 mb-1">Sign up to Zluri</h3>

			{location.state?.data?.email && (
				<p className="font-12 black mb-3">
					Invitation code required to sign up to Zluri was sent to{" "}
					<a className="">{location.state?.data?.email}</a>
				</p>
			)}

			<p>
				<a
					onClick={() => {
						history.push("/request", {
							email: location.state?.data?.email,
						});
					}}
					className=" font-12 pointer"
				>
					Dont have an invite code?
				</a>
			</p>

			<input
				value={userEmail}
				type="email"
				onChange={onChangeUserEmailHandler}
				placeholder="Email"
			/>

			<input
				value={inviteCode}
				type="text"
				onChange={(e) => onChangeHandler(e)}
				placeholder="Invite Code"
				maxLength={10}
			/>

			{isError && (
				<p className="font-12 red  mb-3 mt-2">
					Email or Invite Code is invalid
				</p>
			)}
			<button
				className="btn btn-primary font-14 text-center mt-3"
				disabled={!inviteCode || !userEmail}
				onClick={() => {
					handleSubmit();
				}}
			>
				{isSubmitting && (
					<span style={{ marginRight: "10px" }}>
						<Spinner size="sm" animation="border" />
					</span>
				)}{" "}
				Continue to Signup
			</button>
			{/* <button
				className="btn btn-link font-14 text-center mt-3"
				onClick={() => {
					handleSubmit();
				}}
			>
				{isSubmitting && (
					<span style={{ marginRight: "10px" }}>
						<Spinner size="sm" animation="border" />
					</span>
				)}{" "}
				Resend invite code
			</button> */}
		</div>
	);
}
