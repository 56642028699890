export function getUrlVars() {
	let url = window.location.href,
		vars = {};
	url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
		key = decodeURIComponent(key);
		value = decodeURIComponent(value);
		vars[key] = value;
	});
	return vars;
}

export function validateEmail(email) {
	return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email
	);
}

export function checkSpecialCharacters(
	value,
	allowFewSpecialChars = false,
	returnKey = false
) {
	if (!value) {
		return;
	}
	let invalidKey;

	const whiteListedCharsForSearching = "@,&_*+.#"; //for reference
	const splChars = allowFewSpecialChars
		? "<>!$%^()[]{}?:;|'\"\\~`="
		: "<>@!#$%^&*()_+[]{}?:;|'\"\\,~`=";
	for (let i = 0; i < splChars.length; i++) {
		if (value.indexOf(splChars[i]) > -1) {
			invalidKey = splChars[i];
			return returnKey ? invalidKey : true;
		}
	}
	return false;
}