import React from "react";
import "./request-success.css";
import email from "../../../../assets/icons/email.svg";
import { useLocation } from "react-router-dom";

export function RequestSuccess(props) {
	const location = useLocation();
	return (
		<div className="request-success text-center">
			<img src={email} className="mb-4" />
			<h3 className="bold-700 font-22 black-1 mb-1">
				Your invite code is on it’s way
			</h3>

			<p className="font-14 black mb-3">
				Please wait while we verify your request. We typically dispatch
				invite codes within a few hours.
			</p>
			<p className="font-12 black mb-3">
				Invite code will be sent to{" "}
				<span className="primary-color">
					{location?.state?.data?.email}
				</span>
			</p>
		</div>
	);
}
