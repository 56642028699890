import { trackActionSegment } from "./segment";
import {
	TriggerIssue
} from "./sentry"
export function getLoginURL() {
	const localStorageFE = localStorage.getItem("frontendUrl");
	return localStorageFE ? localStorageFE : process.env.REACT_APP_AUTH0_REDIRECT_URI;
}

export function loginWithGoogle(code) {
	trackActionSegment("Signing up using google sso", { code });
	window.webAuth.authorize(
		{
			connection: "google-oauth2",
			user_metadata: {
				signup_code: code,
			},
		},
		function (err, authResult) {
			const err_message = err.message || "Error in google-oauth2";
			TriggerIssue(err_message, err, "signup-client:google-oauth2");
		}
	);
}

export function loginWithOffice(code) {
	window.webAuth.authorize(
		{
			connection: "windowslive",
			user_metadata: {
				signup_code: code,
			},
		},
		function (err, authResult) { }
	);
}
