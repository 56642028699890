import * as Sentry from "@sentry/react";

export function InitializeSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ID,
    environment: process.env.REACT_APP_ENV,
  });
}
export function TriggerIssue(message, data = {}, tag) {
  console.error(data);
  try {
    let obj = {};
    if (typeof data === "object" && data !== null) {
      Object.keys(data).forEach((item) => {
        switch (item) {
          case "response":
            if (data["response"]) {
              try {
                const temp = {};
                temp.msg = data["response"]["data"];
                temp.stringify = JSON.stringify(
                  data["response"]["data"]
                );
                obj["response-data"] = temp;
                obj[`response-status`] =
                  data["response"]["status"];
              } catch (err) {
                console.log(err);
              }
            }
            break;
          case "config":
            if (data["config"]) {
              ["data", "url", "method"].forEach((item) => {
                obj[`config-${item}`] = data["config"][item];
              });
            }
            break;
          case "request":
          case "toJSON":
            break;
          case "message":
            obj["msg"] = data[item];
            break;
          default:
            obj[item] = data[item];
            break;
        }
      });
    } else {
      obj = { _err: data };
    }
    if (tag) {
      Sentry.setTag("module", tag);
    } else {
      Sentry.setTag(null, null);
    }
    Sentry.captureException(new Error(message), { extra: obj });
  } catch (err) {
    console.log(err);
  }
}