import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { trackPageSegment } from "./common/segment";
import { SignUp } from "./modules/signup/containers/signup/signup";

function App() {
	const location = useLocation();

	useEffect(() => {
		trackPageSegment();
	}, [location]);

	return (
		<div className="App">
			<Switch>
				<Route path="/">
					<SignUp />
				</Route>
			</Switch>
		</div>
	);
}

export default App;
