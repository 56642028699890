import axios from "axios";
const localStorageBE = localStorage.getItem("backendUrl");
const backendUrl = localStorageBE ? localStorageBE : process.env.REACT_APP_API_URL;

// DEFAULTS FOR all the instance of axios
axios.defaults.headers.common["strict-transport-security"] =
	"max-age=63072000; includeSubDomains; preload";
axios.defaults.headers.common["Content-Security-Policy"] =
	"upgrade-insecure-requests";
axios.defaults.headers.common["X-Frame-Options"] = "SAMEORIGIN";
axios.defaults.headers.common["X-Content-Type-Options"] = "nosniff";
axios.defaults.headers.common["Referrer-Policy"] = "same-origin";
axios.defaults.headers.common["X-XSS-Protection"] = "1; mode=block";
axios.defaults.headers.common["Permissions-Policy"] =
	"geolocation=(self), midi=(self), push=(self), sync-xhr=(self), microphone=(self), camera=(self), magnetometer=(self), gyroscope=(self), speaker=(self), vibrate=(self), fullscreen=(self), payment=(self)";

const client = axios.create({
  baseURL: backendUrl
})
export default client;
