import React, { useEffect, useState } from "react";
import { Col, Form, Spinner } from "react-bootstrap";
import Axios from "axios";
import "./signup-request-form.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory, useLocation } from "react-router-dom";
import { trackActionSegment } from "../../../../common/segment";
import client from "../../../../axios/client";
import { checkSpecialCharacters } from "../../../../common/util";
export function SignUpRequestForm(props) {
	const location = useLocation();
	const history = useHistory();
	const [validated, setValidated] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formContext, setFormContext] = useState();
	const [form, setForm] = useState(
		Object.assign({ country: "IN" }, location.state)
	);
	const [errors, setErrors] = useState({});
	const [errorDescription, setErrorDescription] = useState(null);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
	};

	useEffect(() => {
		if (location && !location?.state?.email) {
			history.push("/");
		}
	}, []);

	useEffect(() => {
		setIsSubmitting(false);
		setErrorDescription(null);
		if (Object.entries(errors).length) {
			setErrors(findFormErrors());
		}
	}, [form]);

	const handleSubmit = (event) => {
		setFormContext(event.currentTarget);
		event.preventDefault();
		event.stopPropagation();
		const newErrors = findFormErrors(event.currentTarget);
		if (
			Object.keys(newErrors).length > 0 ||
			event.currentTarget.checkValidity() === false
		) {
			setErrors(newErrors);
		} else {
			setIsSubmitting(true);
			trackActionSegment("Submitting request to signup", form);

			client.post(
				"/request-invite",
				Object.assign({}, form)
			)
				.then((res) => {
					if (res.data.errors) {
						setErrorDescription(res.data.errors);
						return;
					}
					if (res.data.success) {
						history.push("/request-sent", {
							data: Object.assign({}, form, location.state),
						});
					} else {
						setErrorDescription(
							"Something went wrong, Please try again later"
						);
					}
				})
				.catch((err) => {
					const errors = err?.response?.data?.errors?.errors || [];
					if (errors.length) {
						if (errors[0].param === "phone_number") {
							setErrorDescription(
								"Please enter valid phone number"
							);
						}
					} else {
						setErrorDescription(
							"Something went wrong please try again later."
						);
					}
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		}
		setValidated(true);
	};

	const findFormErrors = (context) => {
		const validateFormContext = context || formContext;
		const { first_name, last_name, company, country, phone_number } = form;
		const newErrors = {};

		if (
			!first_name ||
			first_name === "" ||
			!validateFormContext[0].validity.valid || checkSpecialCharacters(first_name)
		) {
			newErrors.first_name = checkSpecialCharacters(first_name) ? "Please enter valid first name" : "Please enter your first name";
		}

		if (
			!last_name ||
			last_name === "" ||
			!validateFormContext[1].validity.valid || checkSpecialCharacters(last_name)
		) {
			newErrors.last_name = checkSpecialCharacters(last_name) ? "Please enter valid last name" : "Please enter your last name";
		}
		if (!company || !validateFormContext[2].validity.valid || checkSpecialCharacters(company)) {
			newErrors.company = checkSpecialCharacters(company) ? "Please enter valid company name" : "Please enter your company name";
		}

		if (!phone_number || !isValidPhoneNumber(phone_number, country)) {
			newErrors.phone_number = "Please enter valid phone number"
		}

		return newErrors;
	};

	return (
		<div className="request-form">
			<h3 className="bold-700 font-22 black-1 mb-1">Signup</h3>

			<p className="font-12 black mb-3">
				Access to Zluri is on an invite-only basis only. Please fill out
				this form and receive an invite code to sign up to Zluri.
			</p>
			<p>
				<a
					onClick={() => {
						history.push("/invite-code", {
							data: Object.assign({}, form, location.state),
						});
					}}
					className=" font-12 pointer"
				>
					Already have an invite code?
				</a>
			</p>

			<div className="self-request-form mb-3">
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<div className="d-flex justify-content-between">
						<Form.Group
							as={Col}
							className="mb-2 me-2"
							controlId="first_name"
						>
							<Form.Label>First Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="First Name"
								required
								onChange={(e) =>
									setField("first_name", e.target.value)
								}
								isInvalid={!!errors.first_name}
							/>
							<Form.Control.Feedback
								type="invalid"
								className="font-12 ps-0"
							>
								{errors.first_name}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group
							as={Col}
							className="mb-2"
							controlId="last_name"
						>
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Last Name"
								required
								onChange={(e) =>
									setField("last_name", e.target.value)
								}
								isInvalid={!!errors.last_name}
							/>
							<Form.Control.Feedback
								type="invalid"
								className="font-12 ps-0"
							>
								{errors.last_name}
							</Form.Control.Feedback>
						</Form.Group>
					</div>

					<div className=" d-flex justify-content-between">
						<Form.Group
							as={Col}
							className="mb-2 me-2"
							controlId="company"
						>
							<Form.Label> Company</Form.Label>
							<Form.Control
								type="text"
								placeholder="name@company.com"
								required
								onChange={(e) =>
									setField("company", e.target.value)
								}
								isInvalid={!!errors.company}
							/>
							<Form.Control.Feedback
								type="invalid"
								className="font-12 ps-0"
							>
								{errors.company}
							</Form.Control.Feedback>
						</Form.Group>
					</div>

					<div className="request-form-phonenumber">
						<label className="mb-3">Phone Number</label>
						<PhoneInput
							international
							onCountryChange={(country) => {
								console.log("country : ", country)
								setField("country", country);
							}}
							placeholder="Enter phone number"
							defaultCountry="IN"
							onChange={(phone) => {
								setField("phone_number", phone);
							}}
							error={
								form.phone_number
									? isValidPhoneNumber(form.phone_number, form.country)
										? undefined
										: "Invalid phone number"
									: "Phone number required"
							}
						/>
						{!errors.phone_number && form.phone_number && !isValidPhoneNumber(form.phone_number, form.country) && (
							<p className="font-12 red mt-1">
								Please enter valid phone number
							</p>
						)}
						{
							errors.phone_number ? <p className="font-12 red mt-1">
								{errors.phone_number}
							</p> : null
						}
					</div>

					<button
						type="submit"
						className="btn btn-primary font-14 text-center mt-4"
						disabled={isSubmitting}
					>
						{isSubmitting && (
							<span style={{ marginRight: "10px" }}>
								<Spinner size="sm" animation="border" />
							</span>
						)}{" "}
						Request Invite
					</button>
				</Form>
			</div>

			{errorDescription && (
				<p className="font-12 red ms-3  text-center">
					{errorDescription}{" "}
				</p>
			)}
		</div>
	);
}
