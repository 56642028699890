import React, { useState } from "react";
import Slider from "react-slick";
import zluri from "../../../../assets/icons/zluri.svg";
import zluri_b from "../../../../assets/icons/zluri_black.svg";
import { EnterEmail } from "../enter-email/enter-email";
import { InviteCode } from "../invite-code/invite-code";
import { SignUpForm } from "../signup-form/signup-form";
import { SignUpRequestForm } from "../signup-request-form/signup-request-form";
import { getLoginURL } from "../../../../common/auth";
import "./signup.css";
import { RequestSuccess } from "../request-success/request-success";
import { Route, Switch } from "react-router-dom";

const SLIDES = [
	{
		image: "https://zluri-assets-new.s3.us-west-1.amazonaws.com/Frame+39+(1)-min.webp",
		title: "Discover,manage & optimize your SaaS Stack",
		desciption:
			"Unearth every single app being used in your organization with our most comprehensive discovery methods, and manage them from a single dashboard.",
		subTitle:
			"Hundreds of companies across the globe manage their SaaS with Zluri",
		subImage:
			"https://zluri-assets-new.s3.us-west-1.amazonaws.com/images/12.webp",
		slider: false,
	},
	{
		image: "https://zluri-assets-new.s3.us-west-1.amazonaws.com/Group+1206+(1)-min.webp",
		title: "Automate IT tasks with workflows",
		desciption:
			"Save time & money while reducing security risks through our automated workflows",
		subTitle: "Rated as a Leader amongst SaaS management platforms",
		subImage:
			"https://zluri-assets-new.s3.us-west-1.amazonaws.com/images/22.webp",
		slider: false,
	},
	{
		image: "https://zluri-assets-new.s3.us-west-1.amazonaws.com/Frame+38+(2)-min.webp",
		title: "Manage vendors, contracts, and renewals in a single place",
		desciption:
			"Get a single source of truth for all your SaaS vendors & contracts and stay on top of renewals",
		slider: true,
	},
];

const SLIDES_MANAGE_VENDOR = [
	{
		image: "https://zluri-assets-new.s3.us-west-1.amazonaws.com/images/Ellipse+919.webp",
		title: "Zluri app helps us to achieve control and full visibility for all our SaaS applications used by our organization.",
		name: "Gilad S",
		profession: "Information Technology Engineer - Guesty",
	},
	{
		image: "https://zluri-assets-new.s3.us-west-1.amazonaws.com/images/1516778637464-removebg-preview+1.webp",
		title: "Zluri’s automated Saas management platform helped us discover the usage across 100+ applications in the company in its single dashboard. Zluri’s Saas buying helped us save USD 250,000 within six months by carrying out data-backed negotiations with Saas vendors.",
		name: "Narasimha Reddy",
		profession: "CFO  - MoEngage",
	},
	{
		image: "https://zluri-assets-new.s3.us-west-1.amazonaws.com/images/image+16+(1).webp",
		title: "Quick and compact explanation, able to easily understand. Very efficient. Tool is rich and helps me to optimize the SaaS that our company used.",
		name: "Mirasz A",
		profession: "Head of Infrastructure - Koinworks",
	},
];

export function SignUp(props) {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		dotsClass: "slick-dots signup-desc-dots",
	};
	const settingsSub = {
		dots: false,
		infinite: true,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
	};

	const slidesManageVendor = SLIDES_MANAGE_VENDOR.map((slide, index) => (
		<div
			className="sub-slide-item  d-flex justify-content-center"
			key={index}
		>
			<div className="sub-slide-item-image-container me-3 ms-2">
				<img src={slide.image} alt="" width={50} />
			</div>
			<div className="sub-slide-item-desc-container">
				<h3 className=" font-12  mb-2 white">{slide.title}</h3>
				<p
					className="font-10 mb-1 bold-700 "
					style={{ color: "rgb(255 255 255 / 80%)" }}
				>
					{slide.name}
				</p>
				<p
					style={{ color: "rgb(255 255 255 / 80%)" }}
					className="mb-0 o-6 font-8"
				>
					{slide.profession}
				</p>
			</div>
		</div>
	));

	const slides = SLIDES.map((slide, index) => (
		<div className="slide-item " key={index}>
			<div className="slide-item-image-container text-center d-flex align-items-center mb-2">
				<img src={slide.image} alt="" />
			</div>
			<div className="slide-item-desc-container text-center">
				<h3 className="bold-600 font-22  mb-2 white">{slide.title}</h3>
				<p
					className="font-14"
					style={{ color: "rgb(255 255 255 / 80%)" }}
				>
					{slide.desciption}
				</p>
			</div>
			{!slide.slider ? (
				<div className="slide-item-desc-sub-container text-center">
					<h4
						style={{ color: "#FFC117" }}
						className="font-600 font-12"
					>
						{slide.subTitle}
					</h4>
					<img src={slide.subImage} alt="" />
				</div>
			) : (
				<section className="sub-slider  p-3 ps-4 pe-4">
					<Slider {...settingsSub}>{slidesManageVendor}</Slider>
				</section>
			)}
		</div>
	));

	return (
		<div className="container-fluid g-0">
			<div className="row g-0 ">
				<div className="col-md-12">
					<div className="row g-0 signup-page d-flex align-items-stretch">
						<div className="col-md-7 col-sm-12 bg-primary order-2 order-md-2">
							<div className="signup-desc-container  d-flex flex-column justify-content-between">
								<header className="p-4 pb-1">
									<a
										href="https://zluri.com/"
										className="d-none d-md-block"
									>
										<img alt="" src={zluri} />
									</a>
								</header>
								<section>
									<Slider {...settings}>{slides}</Slider>
								</section>
								<div className=" d-none d-md-block"></div>
								<footer className="p-2 footer-mobile d-md-none ">
									<ul className="d-flex justify-content-center font-12 grey-1 o-6 my-2">
										<li>
											<ul className="d-flex">
												<li className="me-3">
													<a
														className="text-reset text-decoration-none"
														rel="noreferrer"
														href="https://www.zluri.com/terms-and-conditions"
														target="_blank"
													>
														Terms and Conditions
													</a>
												</li>
												<li>
													<a
														className="text-reset text-decoration-none"
														rel="noreferrer"
														href="https://www.zluri.com/privacy-policy"
														target="_blank"
													>
														Privacy Policy
													</a>
												</li>
											</ul>
										</li>
									</ul>
								</footer>
							</div>
						</div>
						<div className="col-md-5 col-sm-12 order-1 order-md-2 signup-container p-5 pb-0 d-flex flex-column justify-content-between">
							<header className="d-flex justify-content-between">
								<a
									href="https://zluri.com/"
									className="d-md-none"
								>
									<img alt="" src={zluri_b} />
								</a>
								<a className="d-none d-md-block"></a>
								<a
									className="btn btn-primary-inverse font-13"
									href={getLoginURL()}
								>
									Log in
								</a>
							</header>
							<section>
								<div className="signup-section">
									<Switch>
										<Route exact path="/">
											<EnterEmail />
										</Route>
										<Route exact path="/request">
											<SignUpRequestForm />
										</Route>
										<Route exact path="/request-sent">
											<RequestSuccess />
										</Route>
										<Route exact path="/signup">
											<SignUpForm />
										</Route>
										<Route exact path="/invite-code">
											<InviteCode />
										</Route>
									</Switch>
								</div>
							</section>
							<div className="d-md-none"></div>
							<footer
								className="p-2 d-none d-md-block"
								style={{
									borderTop: "1px solid #ddd",
								}}
							>
								<ul className="d-flex justify-content-center font-12 grey-1 o-6 my-2">
									<li>
										<ul className="d-flex">
											<li className="me-3">
												<a
													className="text-reset text-decoration-none"
													rel="noreferrer"
													href="https://www.zluri.com/terms-and-conditions"
													target="_blank"
												>
													Terms and Conditions
												</a>
											</li>
											<li>
												<a
													className="text-reset text-decoration-none"
													rel="noreferrer"
													href="https://www.zluri.com/privacy-policy"
													target="_blank"
												>
													Privacy Policy
												</a>
											</li>
										</ul>
									</li>
								</ul>
							</footer>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}